.hero2{
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    height:50vh;
    width:100%;
    background:rgba($color: #000000, $alpha:0.6);

    .heading{
        width:100%;
        height:100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color:rgb(248,217,15);

        h1{
            font-size: 2.4rem;

        }

        p{
            font-size: 1.4rem;
            text-align: center;
        }
    }
}

.hero2::before{
    content:"";
    background:url("../../assets/P1.jpg");
    background-position:center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index:-1;
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
}

@media screen and (max-width:640px){
    .hero2{
        .heading{
            h1{
                font-size:1.8rem;
    
            }
    
            p{
                font-size:1.2rem;
                text-align: center;
            }
        }
    }
} 