.skill-container{
    margin:15px 25px;
    width:110px;
    img{
        width: 100%;
        height:100%;
    }

    p{
        font-size: 1rem;
        font-weight: 500;
    }
}