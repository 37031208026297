.footer {
  .footer-main {
    height: 30vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .footer-left {
      display: flex;
      flex-direction: column;
      align-items: left;
      color: #fff;
      margin: 40px;

      .footer-area {
        margin: 5px;
        .footer-icons {
          font-size: 25px;
          margin-right: 10px;
        }
      }
    }
    .footer-center {
      justify-content: center;
      text-align: center;
      margin-top: 40px;

      p {
        padding: 5px;
      }
    }

    .footer-right {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      margin-right: 60px;

      .pro-media-icons {
        justify-content: space-around;
      }
    }
  }

  .footer-end {
    height: 60px;
    color: #fff;
    align-items: center;
    justify-content: center;

    .foot-end-head {
      margin-left: 45%;
    }

    .foot-end-tail {
      margin-left: 40%;
    }

    .foot-end-icons {
      justify-content: center;
      align-items: center;
      margin-left: 44%;
      padding: 15px;
    }
  }
}

@media screen and (max-width: 640px) {
  .footer {
    display: flex;
    flex-direction: column;
    .footer-main {
      display: flex;
      flex-direction: column;
    
    .footer-right{
        .btn{
            margin-left:100px;
        }
    }
    }
  }
  .footer-end{
    margin-top: 250px;

    .foot-end-head {
        margin-left: -15%;
      }
  
      .foot-end-tail {
        margin-left: -30%;
      }
  }
}
