.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:1rem;
  z-index: 10;
  position:fixed;
  width:100%;
  height: 100px;

  .nav-header {
    color: #f8d90f;
    list-style: none;
  }

  .nav-content {
    display: flex;
    .nav-menu {
      display: flex;
      li {
        list-style: none;
        padding: 1rem;
        a {
          list-style: none;
          font-size: 1.2rem;
          font-size: 500;
          list-style: none;
        }
      }
    }
    .hamburger {
      display: none;
    }
  }
}

.navbar-bg{
  width:100%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: 0.5s;
}


@media screen and (max-width: 640px) {
  .navbar {
    z-index: 10;
    position:fixed;
    width:90%;
    height: 70%;
    top:-30%;
    left:0;
    .nav-header.active {
      // position: absolute;
      // margin-top: -20px;
    }
    .nav-content {
      // z-index: 3;
      .nav-menu {
        top: 60%;
        left: -120%;
        position: absolute;
        flex-direction: column;
        justify-content: right;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color:rgba(0, 0, 0, 0.8);
        transition: 0.3s;
        li {
          padding: 0.5rem 0;
          a {
            font-size: 2rem;
          }
        }
      }

      .nav-menu.active {
        left: -6%;
      }
      .hamburger {
        z-index:15;
        display: initial;
      }
    }
  }
  .navbar-bg{
    width:100%;
    // background-color: rgba(0, 0, 0, 0.6);
    // transition: 0.5s;
  }
}
