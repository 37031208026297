.project-card{
    border:1px solid #eee;
    height:70vh;
    width:25%;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding:20px;
    .project-img{
        justify-content: center;
        align-items: center;
        width:200px;
        height:200px;
        margin-left: 80px;
        img{
            width:100%;
            height:100%;
        }

    }

    h2{
        padding:10px;
    }

    p{
        padding:10px;
    }

    .viewsource-btn{
        margin:30px;
        display:flex;
        justify-content: space-between;
    }
}

.project-card:hover{
    background-color:#271f1f;
    cursor: pointer;
    transition: 0.5s;
}


@media screen and (max-width:740px){
    .project-card{
      margin: 30px auto;
      height:70vh;
      width:80%;
    }
    
}