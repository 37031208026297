@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@300;400;500;900&display=swap");


*{
  box-sizing:border-box;
  margin:0;
  padding:0;
  font-family: 'Outfit', sans-serif;
  font-family: 'Roboto', sans-serif;
}


body{
  
  background-color:#000;
}

h1,h2,h3,h4,h5,h6,p,a{
  color:#fff;
  text-decoration:none;
}

ul{
  list-style-type:none;
}

.btn{
  background-color:rgb(248,217,15);
  color:#222;
  border:1px solid #fff;
  border-radius:5px;
  padding:12px 32px;
  font-size:1rem;
  font-weight:500;
  cursor:pointer;
  text-transform:uppercase;
  margin:10px;
}

.btn-light{
  background:transparent;
  color:#fff;
  margin:10px;
}

.btn:hover{
  background:transparent;
  color:white;
  transition:0.3s;
}

.btn-light:hover{
  //background:#810CA8;
  background-color:#FF1E00;
  color:#fff;
  transition:0.3s;
}

/*
  #0C134F
  background-image: url("./assets/HomeBackgroundImg.jpg");
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: center;
  background-origin: content-box;
*/





/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*/
