.projectdetailscard{
    border:1px solid #eee;
    border-radius: 40px;
    height:100vh;
    width:90%;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    display: flex;
    color:white;
    margin:70px;
    padding:80px;
    .prodetailsleft{
        flex:7;
        align-items: center;
        text-align: left;
        padding:10px;
        margin-left: 30px;
        line-height:30px;
       
        h1{
            padding:10px;
        }
        .projectdetailsdata{
            padding:5px;
            //line-height:5px;
            list-style: circle;
            list-style-type: circle;
        }
    }

    .prodetailsright{
        flex:5;
        justify-content: center;
        align-items: center;
        width:200px;
        height:200px;
        margin-top:-150px;
        img{
            width:100%;
            height:100%;
        }
    }

    .back-btn{
        position:absolute;
        margin: 750px 0 0 0;

    }
}